import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// custom
import StatusWidget from 'components/StatusWidget';
import Loading from 'components/Loading';

// styles
import styles from './styles';
import like from './like.png';

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getDashboard: PropTypes.func,
  };

  state = {
    dahboard: undefined,
    loading: true,
  };

  constructor(...args) {
    super(...args);
    const { getDashboard } = this.props;
    getDashboard().then((r) => {
      if (r.success) {
        this.setState({
          dashboard: r.payload,
          loading: false,
        })
      }
    })
  }

  render() {  
    const { classes } = this.props;
    const { dashboard, loading } = this.state;

    if (loading) {
      return (
        <Loading />
      );
    }

    return (
      <Grid 
        container 
        className={classes.dashboard}
      >
        {
          dashboard && dashboard.map(d => (
            <Grid item xs={12} md={4} lg={3} xl={2}>
              <StatusWidget 
                statusColor={d.statusColor}
                backgroundColor={d.backgroundColor}
                icon={d.icon}
                title={d.title}
                caption={d.caption}
                unit={d.unit}
                value={d.value}
                location={d.location}
                theme={d.theme}
                callbackURL={d.callbackURL}
                id={d.id}
              />
            </Grid>
          ))
        }
      </Grid>
    )
  }
}
export default withStyles(styles)(App);
