import getenv from 'getenv';

const { _APP_CONFIG } = window;

// api
export const REACT_APP_API = _APP_CONFIG.endpoint;
export const REACT_APP_FRONT_BASE = _APP_CONFIG.baseHostname;

// centrifugo
export const CENTRIFUGO_URL = _APP_CONFIG.centrifugo.url;
export const CENTRIFUGO_TOKEN = _APP_CONFIG.centrifugo.token;