export default theme => ({
  card: {
    padding: 18,
    margin: 16,
    // background: 'linear-gradient(145deg, rgba(238,243,248,1) 0%, rgba(205,222,236,1) 100%)',
    border: 'solid 4px #EBF3FC',
    borderRadius: 40,
    boxShadow: 'inset -2px -2px 8px rgb(255,255,255, 0.6), inset 2px 2px 8px rgb(96,125,139, 0.2), 5px 5px 10px rgb(96,125,139, 0.2) , -5px -5px 20px rgba(255,255,255,0.6)'
  },
  coloredCard: {
    padding: 18,
    margin: 16,
    // background: 'linear-gradient(145deg, rgba(238,243,248,1) 0%, rgba(205,222,236,1) 100%)',
    border: 'solid 4px #EBF3FC',
    borderRadius: 40,
    boxShadow: 'inset -60px -60px 120px rgb(0,0,0, 0.1), inset 60px 60px 120px rgb(255,255,255, 0.3), 5px 5px 10px rgb(96,125,139, 0.2) , -5px -5px 20px rgba(255,255,255,0.6)'
  },
});
