import {
    GET,
  } from 'constants/methods';

  export function getDashboard() {
    const url = '/v3/reports/tasks/versatile';
    return {
      method: GET,
      url,
    };
  }
