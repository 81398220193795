import React from 'react';
import theme from 'themes/theme';
import './App.css';

// custom components
import Root from 'components/Root';

// material-ui
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

function App() {
  const muiTheme = createMuiTheme({
    palette: { ...theme },
    typography: {
      fontFamily: [
        'open-sans, sans-serif',
      ].join(','),
      title: {
        fontSize: 30,
        color: '#5A6169',
      },
      h4: {
        fontSize: 70,
        color: '#5A6169',
        textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
      },
      body2: {
        color: '#8F9FB2',
        opacity: 0.7,
        fontSize: 17,
      },
      body1: {
        color: '#8F9FB2',
        fontSize: 15,
      },
      caption: {
        color: '#97A1AB',
      }
    },
  });

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Root />
    </MuiThemeProvider>
  );
}

export default App;
