import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

// custom
import Card from 'components/Card';

// styles
import styles from './styles';

class StatusWidget extends Component {
  static propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    caption: PropTypes.string,
    statusColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    value: PropTypes.number,
    unit: PropTypes.string,
    location: PropTypes.string,
    theme: PropTypes.string,
    callbackURL: PropTypes.string,
    icon: PropTypes.string,
  };

  render() {
    const { 
      classes,
      title,
      caption,
      statusColor,
      backgroundColor,
      value,
      unit,
      location,
      theme,
      icon,
      callbackURL,
    } = this.props;

    return (
      <div
        onClick={() => window.open(callbackURL, '_blank')}
        style={{ cursor: callbackURL ? 'pointer' : undefined }}
      >
        <Card
          backgroundColor={backgroundColor}
          theme={theme}
        >
          <Grid 
            container
            alignItems="center"
            spacing={16}
          >
            <Grid item style={{ width: 80 }}>
              <Avatar 
                style={{
                  width: 70,
                  height: 70,
                  background: statusColor,
                  boxShadow: 'inset -8px -8px 18px rgb(255,255,255, 0.3), inset 2px 2px 8px rgb(0,0,0, 0.3), 5px 5px 10px rgb(0,0,0, 0.1) , -5px -5px 8px rgba(255,255,255,0.1)'
                }}
              >
                <img 
                  src={icon}
                  style={{ height: 40, width: 40, filter: 'invert(100%)' }}
                />
              </Avatar>
            </Grid>
            <Grid item style={{ maxWidth: 'calc(100% - 80px)' }}>
              <Typography variant="title" style={{ color: theme === 'dark' ? 'white' : undefined, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'block' }}>
                {title}
              </Typography>
              <Typography variant="body1" style={{ color: theme === 'dark' ? 'rgba(255,255,255,0.56)' : undefined }}>
                {caption}
              </Typography>
            </Grid>
          </Grid>
          <Grid 
            container
            alignItems="flex-end"
            justify="space-between"
            spacing={16}
          >
            <Grid item style={{ textAlign: 'right', maxWidth: '50%' }}>
              <Grid item>
                <Typography variant="body2" style={{ color: theme === 'dark' ? 'rgba(255,255,255,0.56)' : undefined }}>
                  {location}
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ textAlign: 'right', maxWidth: '50%' }}>
              <Typography variant="h4" style={{ color: theme === 'dark' ? 'white' : undefined }}>
                {value}
              </Typography>
              <Typography variant="body2" style={{ color: theme === 'dark' ? 'rgba(255,255,255,0.56)' : undefined }}>
                {unit}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(StatusWidget);
