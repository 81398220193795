import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// components
import App from 'components/App';
import Centrifugo from 'components/Centrifugo';

// actions
import * as Actions from 'actions';

// styles
import styles from './styles';

// helpers
import { getJsonFromUrl } from 'helpers';

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  websocketMessageReceived: (...args) => dispatch(Actions.websocketMessageReceived(...args)),
  getDashboard: (...args) => dispatch(Actions.getDashboard(...args)),
});

class Root extends Component {
  static propTypes = {
    classes: PropTypes.object,
    websocketMessageReceived: PropTypes.func,
    getDashboard: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      urlParams,
    };
  }

  render() {
    const {
      websocketMessageReceived,
      getDashboard,
      classes,
    } = this.props;

    return (
      <div
        className={classes.container}
      >
        <Centrifugo
          websocketMessageReceived={websocketMessageReceived}
        >
          <App 
            getDashboard={getDashboard}
          />
        </Centrifugo>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Root));
